:root {
  color: #fff;
  cursor: none;
  background: #212121;
  font-family: "Exo 2", sans-serif;
  font-size: calc(.85rem + .45vw);
  line-height: 1.4;
}

h1 {
  margin-top: 0;
  font-size: 3rem;
}

h1, h2, h3, strong {
  font-style: italic;
  font-weight: 900;
}

body {
  z-index: -2;
  background-image: linear-gradient(-160deg, #00dbdb, #d104c7);
  background-size: 100% 100%;
  position: relative;
}

.kontainer {
  overflow: hidden;
}

.title {
  z-index: -1;
  margin-top: 0;
  position: relative;
}

.fdi {
  pointer-events: none;
  position: absolute;
  top: 5.5rem;
  left: 3.6rem;
}

.fdi i {
  transform: rotate(var(--rotation));
  display: block;
  position: absolute;
}

.fdi span {
  will-change: transform;
  background-color: var(--color);
  width: .15rem;
  height: .25rem;
  animation: fliegen calc(5s * var(--rnd)) infinite cubic-bezier(.19, 1, .22, 1);
  display: block;
  position: absolute;
}

@keyframes fliegen {
  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(calc(40vh + 50vh * var(--rnd))) rotateZ(calc(1060deg * var(--rnd)));
  }
}

a {
  color: #fff;
}

a:after {
  content: "➚";
  margin-left: .3em;
  font-size: .75em;
  display: inline-block;
  position: relative;
  top: -.2em;
}

a:hover {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
}

h1, h2 {
  margin-top: 1.5em;
  margin-bottom: 1rem;
  margin-left: -1rem;
}

body {
  min-height: 100vh;
  font-weight: 100;
}

.l-design-width {
  box-sizing: border-box;
  background-repeat: 10px;
  max-width: 40rem;
  margin: 1rem auto;
  padding: 2rem;
}

p, img {
  max-width: 20rem;
}

img {
  max-width: 12rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 1.3rem;
  display: block;
}

details {
  margin-top: 1em;
}

details p {
  margin-left: 1.4em;
}

summary {
  margin-bottom: .25em;
  font-size: 1.2em;
}

summary:focus {
  outline: none;
}

.outro {
  margin-top: 4rem;
}

.logo {
  width: 7rem;
  display: block;
  transform: translate(-3rem, 1rem)rotate(-20deg);
}

.logo svg {
  fill: #fff;
  width: 100%;
  height: auto;
}

.cursor {
  pointer-events: none;
  will-change: transform;
  width: 2rem;
  position: absolute;
  top: -10px;
  left: -8px;
}

.cursor svg {
  fill: #fff;
  width: 100%;
  height: auto;
  transform: rotate(-50deg);
}
/*# sourceMappingURL=index.1141ca75.css.map */
